<script>
  import {page, useForm, inertia} from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Layout from "@/Layouts/Auth.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Tel from "@/Components/Forms/Tel.svelte";
  import Input from "@/Components/Forms/Input.svelte";

  let phoneValue;
  let verifying = false;
  let retry = false;
  let retryText;
  let timer;
  let timeRemaining;

  let formPhone = useForm({
    phone: '',
  });

  let formVerify = useForm({
    code: '',
  });

  const startCountdown = (attempts) => {
    clearInterval(timer);
    retry = false;
    console.log(`Attempt number: ${attempts}`);
    timeRemaining = getRetryTimeout(attempts);
    timer = setInterval(() => countdown(), 1000);
  }

  const countdown  = () => {
    if (timeRemaining === 0) {
      clearInterval(timer);
      retry = true;
      retryText = `Resend code`;
    } else {
      retry = false;
      retryText = `Resend code in ${timeRemaining} seconds`;
      timeRemaining--;
    }
  }

  const getRetryTimeout = (attemptNumber) => {
    const retryTimeouts = {
      1: 30,
      2: 40,
      3: 60,
      4: 90,
      5: 120,
    };

    const maxTimeout = 600;
    const defaultTimeout =
      maxTimeout - Object.values(retryTimeouts).reduce((a, b) => a + b);

    return retryTimeouts[attemptNumber] || defaultTimeout;
  }

  const updatePhone = (event) => {
    console.log(event);
    console.log(event.detail.value);
    phoneValue = event.detail.value;
  }

  const editPhone = () => {
    verifying = false;
    $formPhone.reset();
    $formVerify.reset();
    clearInterval(timer);
    retry = false;
  }

  const retrySend = () => {
    $formPhone
      .post('/app/onboarding/phone/resend', {
        onSuccess: () => {
          verifying = true;
          startCountdown(1);
        }
      });
  }

  const handlePhoneSubmit = () => {
    $formPhone
      .transform(() => {
        return {
          phone: phoneValue,
        };
      })
      .post('/app/onboarding/phone/save', {
        onSuccess: () => {
          verifying = true;
          startCountdown(1);
        }
      });
  }

  const handleVerifySubmit = () => {
    $formVerify.post('/app/onboarding/phone/verify', {
      onError: (errors) => {
        if (errors.code) {
          startCountdown(errors?.attempts);

          if (errors?.attempts === 5 || errors?.resend === true) {
            editPhone();
          }
        }
      }
    });
  }
</script>

<Seo title="Tell us about yourself" />

<Layout title="Tell us about yourself" page={3}>
  {#if !verifying}
    <form method="post" on:submit|preventDefault={handlePhoneSubmit}>
      <div>
        <label for="phone">Phone Number <sup>*</sup></label>
        <Tel
          class="!bg-white !text-sm min-h-10 w-full"
          id="phone"
          name="phone"
          size="sm"
          inputClass="!bg-white !text-sm !rounded min-h-10"
          bind:value={$formPhone.phone}
          loading={$formPhone.processing}
          placeholder="Enter your phone number"
          error={$formPhone.errors.phone}
          on:updateVal={(event) => updatePhone(event)}
          required
        />
      </div>

      <div class="actions">
        <Button
          type="submit"
          size="sm"
          class="max-w-28"
          disabled={!phoneValue}
          loading={$formPhone.processing}
          on:click={handlePhoneSubmit}>Next</Button>

        <a href="/app/onboarding/terms" class="skip" use:inertia>Skip</a>
      </div>
    </form>
  {:else}
    <div class="notes">
      <p>Sent SMS verification to: <strong>{$page.props.auth?.user?.phone}</strong> <button on:click={() => editPhone()} class="edit">edit</button></p>
    </div>

    <form method="post" on:submit|preventDefault={handleVerifySubmit}>
      <div>
        <label for="phone">Phone Number Verification <sup>*</sup></label>
        <Input
          class="!bg-white !text-sm min-h-10 w-full"
          id="code"
          name="code"
          type="text"
          size="sm"
          bind:value={$formVerify.code}
          placeholder="123456"
          error={$formVerify.errors.code}
          description="Enter the code sent to your device."
          required
        />
      </div>

      <div class="actions">
      <Button
        type="submit"
        size="sm"
        class="max-w-28"
        disabled={!$formVerify.code || $formVerify.code.length < 6}
        loading={$formVerify.processing}
        on:click={handleVerifySubmit}>Verify</Button>

        <button class="retry" on:click={() => retrySend()} disabled={!retry}>{retryText ?? ""}</button>
      </div>
    </form>
  {/if}

  <div class="notes">
    <p>We'll use this to back up your account in the event you lose access to your work email.</p>
    <p>NOTE: You can turn on notifications when you get pitched on Pitchfire as well.</p>
  </div>
</Layout>

<style lang="postcss">
  form {
    @apply flex justify-between flex-col;

    & > div {
      @apply mb-4 flex flex-col;
    }

    label {
      @apply text-xs uppercase;

      sup {
        @apply text-red-600 text-sm;
      }
    }

    .actions {
      @apply flex flex-row justify-between items-end;

      a {
        @apply text-xs;
      }
    }
  }

  .notes {
    @apply text-xs mt-4;

    p {
      @apply mb-2;
    }
  }

  .edit {
    @apply text-xs text-blue-600 underline;
  }

  .retry {
    @apply text-xs text-blue-600 underline;

    &:disabled {
      @apply cursor-not-allowed no-underline text-black;
    }
  }
</style>
