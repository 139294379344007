<script>
  import Layout from "./Default.svelte";
  import Logo from "@/Components/Assets/Logo.svelte";

  export let title = 'Welcome to Pitchfire';
  export let page = 1;
</script>

<Layout>
  <div class="outer">
    <div class="wrap">
      <main>
        <Logo href="/" outerClass="block sm:hidden max-w-60 w-full" />

        <div>
          <Logo href="/" outerClass="hidden sm:block max-w-48" />
          <h1>{title}</h1>

          <slot />

          <div class="dots">
            <span class:active={page === 1}></span>
            <span class:active={page === 2}></span>
            <span class:active={page === 3}></span>
            <span class:active={page === 4}></span>
          </div>
        </div>
      </main>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .outer {
    @apply w-full bg-to-top-grad-gray h-screen;

    .wrap {
      @apply flex mx-auto lg:container h-screen items-center justify-center !max-w-screen-xl;
      @apply bg-[url('/assets/profile-cards.webp')] bg-no-repeat bg-center md:bg-left;
    }
  }

  main {
    @apply flex flex-col md:flex-row gap-12 items-center justify-center md:justify-end w-full px-4 h-screen bg-white/80 sm:bg-blue/10 md:bg-transparent;

    & > div {
      @apply order-1 md:order-2 w-full sm:w-96 bg-white rounded-2xl p-6 md:p-10 shadow-xl;
    }
  }

  h1 {
    @apply mt-4 md:mt-7 mb-8 md:mb-10 text-2xl md:text-4xl text-blue;
  }

  .dots {
    @apply flex items-center justify-end mt-10 md:mt-16 space-x-2;

    span {
      @apply w-3 h-3 rounded-full bg-grey-400;

      &.active {
        @apply bg-red-600;
      }
    }
  }
</style>
