<script>
  import Layout from "@/Layouts/Settings.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {page,  useForm} from "@inertiajs/svelte";
  import Button from "../../Components/Buttons/Button.svelte";
  import CKEditor from "@/Components/Forms/CKEditor.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconCopy} from "@/Components/Assets/Icons/index.js";
  import copy from "@/Lib/utils/copy.js";

  const url = $page.props.common.app_url + '/@' + $page.props.auth.user.slug;

  let form = useForm({
    auto_reply: $page.props.auth.user.auto_reply ?? "<p>Thanks for reaching out. Submit your sales pitch using <a href=\"" + url + "\">my Pitchfire link</a> and I'll review as soon as I can." +
      "<br /><br />Pitchfire makes it easy for me to review quick sales pitches from you and determine whether I'm interested or not. In the event that I am interested, I'll be able to book on your calendar. If I'm not, I'll be sure to give you insight into why." +
      "<br /><br />Please respect this process going forward, and only prospect me through Pitchfire." +
      "<br /><br />--" +
      "<br /><br /></p>"
  });

  function handleSave(event) {
    event.preventDefault();

    $form
      .transform((data) => {
        return {
          auto_reply: data.auto_reply,
        };
      })
      .post('/app/settings/plugins/save');
  }
</script>

<Seo title="Gmail/Outlook Plugin Settings" />

<Layout title="Gmail/Outlook Plugin" crumb="Plugins">
  <form method="post" on:submit={handleSave}>
    <fieldset>
      <h2>Auto Reply Text</h2>
      <p>Use the editor below to update the reply used in the Outlook & Gmail plugins</p>

      <CKEditor bind:value={$form.auto_reply} maxLength={2000} />

      {#if $form.errors.auto_reply}
        <p class="error">{$form.errors.auto_reply}</p>
      {/if}

      <button type="button" class="copy" on:click={copy(`${$page.props.common.app_url}/@${$page.props.auth?.user?.slug}`)}>
        <Icon data={IconCopy} />
        Copy Your Personal Link
      </button>
    </fieldset>

    <div class="actions">
      <Button size="sm" on:click={handleSave}>Save Changes</Button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  fieldset {
    @apply mb-8;

    h2 {
      @apply text-xl mb-2;
    }

    p {
      @apply text-sm;

      &.error {
        @apply m-0 mt-1 text-red-600 leading-snug text-xs;
      }
    }
  }

  .copy {
    @apply flex gap-x-3 text-sm leading-6 hover:underline no-underline items-center;
  }

  .actions {
    @apply border-t border-grey-300 pt-8 text-right;
  }
</style>
