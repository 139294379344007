<script>
  import Layout from "@/Layouts/Page.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import Card from "@/Components/Layout/Card.svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import Select from "@/Components/Forms/Select.svelte";

  let form = useForm({
    management_level: '',
    departments: [],
    focuses: []
  });

  let focus_options = [];

  const updateFocus = () => {
    $form.focuses = focus_options = [];
    if ($form.departments?.length > 0) {
      focus_options = $page.props?.focuses.filter(focus => {
        return $form.departments.some(department => department.value === focus.parent_id)
      })
      $form.focuses = focus_options;
    } else {
        focus_options = []
        $form.focuses = []
    }
  };

  const handleSubmit = () => {
    $form
      .transform(data => {
        const departments = data.departments?.map(x => x.value);
        const focuses = data.focuses?.map(x => x.value);
        return {
          management_level: data.management_level,
          departments,
          focuses
        };
      })
      .post('/app/onboarding', {
        onSuccess: () => $form.reset(),
      });
  };
</script>

<Seo title="Onboarding - Profile" />

<Layout>
  <Card title="Tell Us About Yourself" subtitle="Please enter following information in order to proceed further" class="max-w-3xl mx-auto">
    <div class="wrap">
      <Profile
        image={$page.props.auth?.user?.avatar ?? ''}
        imageAlt={$page.props.auth?.user?.name}
        subImage={$page.props.auth?.org?.logo}
        subImageAlt={$page.props.auth?.org?.name}
        title={$page.props.auth?.user?.name}
        subTitle={$page.props.auth?.user?.email}
        theme="border"
        size="sm"
      />

      <form method="post" on:submit|preventDefault={handleSubmit}>
        <div>
          <Select
            name="level"
            label="I am a"
            labelPosition="inline"
            placeholder="select management level"
            size="sm"
            required
            options={$page.props?.management_levels}
            disabled={$form.processing}
            bind:value={$form.management_level}
          />
          {#if $form.errors.management_level}
            <div class="!mt-1 text-red-600 text-sm">{$form.errors.management_level}</div>
          {/if}

          <SelectBox
            name="department"
            label="working in"
            labelPosition="inline"
            placeholder="select department(s)"
            required
            options={$page.props?.departments}
            disabled={$form.processing}
            bind:value={$form.departments}
            on:clear={updateFocus}
            on:change={updateFocus}
          />
          {#if $form.errors.departments}
            <div class="!mt-1 text-red-600 text-sm">{$form.errors.departments}</div>
          {/if}

          <SelectBox
            name="focus"
            label="working on"
            labelPosition="inline"
            placeholder="select area(s) of focus"
            required
            options={focus_options}
            disabled={$form.processing}
            bind:value={$form.focuses}
          />
          {#if $form.errors.focuses}
            <div class="!mt-1 text-red-600 text-sm">{$form.errors.focuses}</div>
          {/if}
        </div>

        <Button
          type="submit"
          class="w-full"
          size="lg"
          loading={$form.processing}
          on:click={handleSubmit}>Done! Let's Go</Button>
      </form>
    </div>
  </Card>
</Layout>

<style lang="postcss">
  .wrap {
    @apply mx-auto md:max-w-xl py-2;
  }

  form {
    & > div {
      @apply flex flex-col gap-4 md:gap-2 my-8;

      div {
        @apply flex flex-col md:flex-row gap-1;
      }
    }
  }
</style>
