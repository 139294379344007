import {toast} from '@zerodevx/svelte-toast'

const options = {
  duration: 5000,
  pausable: true
}

/** @param {string} m */
const success = (m) => toast.push(m, {
  ...options,
  theme: {
    '--toastBackground': '#DBF5F4',
    '--toastColor': '#185452',
    '--toastBarBackground': '#33B5B0'
  }
})

/** @param {string} m */
const warning = (m) => toast.push(m, {
  ...options,
  theme: {
    '--toastBackground': '#FBF1DF',
    '--toastColor': '#734F0D',
    '--toastBarBackground': '#E6A222'
  }
})

/** @param {string} m */
const failure = (m) => toast.push(m, {
  ...options,
  theme: {
    '--toastBackground': '#FCE5E3',
    '--toastColor': '#73120D',
    '--toastBarBackground': '#EB534B'
  }
})

/** @param {string} m */
const info = (m) => toast.push(m, {
  ...options,
  theme: {
    '--toastBackground': '#DCDFF9',
    '--toastColor': '#030412',
    '--toastBarBackground': '#4C5AE1'
  }
})

export {
  success,
  warning,
  failure,
  info
}
