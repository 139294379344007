<script>
  import Layout from "@/Layouts/Auth.svelte";
  import { useForm } from '@inertiajs/svelte';
  import Seo from "@/Components/Seo/Index.svelte";

  let form = useForm({
    terms: false
  });


  const handleSubmit = async () => {
    $form.post('/app/onboarding/terms/save');
  };

</script>

<Seo title="Tell us about yourself" />

<Layout title="Terms & Conditions" page={4}>
  <div class="body">
    <p>Please read and agree to the terms and conditions before proceeding.</p>
    <p>By completing the sign up process, you agree to the <a href="https://www.pitchfire.com/terms-of-service" target="_blank" rel="noreferrer noopener">Terms and Conditions</a> and <a href="https://www.pitchfire.com/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> of Pitchfire.</p>
  </div>

  <form on:submit|preventDefault={(e) => handleSubmit(e)}>
    <label for="terms">
      <input type="checkbox" id="terms" name="terms" bind:checked={$form.terms} required>
      <span>I agree to the terms and conditions <sup>*</sup></span>
    </label>
    {#if $form.errors.terms}
      <p class="error">{$form.errors.terms}</p>
    {/if}

    <div class="actions">
      <button type="submit" on:click|preventDefault={(e) => handleSubmit(e)}>I agree</button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  .body {
    @apply text-sm;
  }

  form {
    @apply flex justify-between flex-col mt-8;

    label {
      @apply text-xs flex items-center justify-start;
    }

    input {
      @apply mr-4;
    }

    p.error {
      @apply m-0 mt-1 text-red-600 leading-snug text-xs;
    }

    .actions {
      @apply text-start;

      button {
        @apply text-sm !bg-blue-700 !text-white	border p-2.5 mt-8 rounded-lg	font-medium w-full 	max-w-28;
      }
    }
  }
</style>
